<template>
  <div>
    <a-card title="系统信息">
      <a-spin :spinning="loading">
        <a-form-model ref="form" :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="24" :md="12">
              <a-form-model-item label="到期时间">
                <a-input :value="$store.state.user.expiryTime" disabled />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import { systemConfigsList } from "@/api/system";

export default {
  name: "SystemConfigs",
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      form: {},
      confirmLoading: false,
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      systemConfigsList()
        .then((data) => {
          this.form = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
